export default {
    name: "SetCashPassword",
    data() {
        return {
            show: false,
            keyValue: ""
        }
    },
    methods: {
        valFun(num) {
          const len = this.keyValue.length
          if (len >= num) {
            const arr = this.keyValue.split('')
            return arr[num - 1]
          } else {
            return ""
          }
        },
        nextFun() {
          if (this.keyValue.length != 6) {
            return
          }
          const isSet = this.$route.path == '/setCashPassword'
          const name = isSet ? "setCashPassword" : "resetCashPassword"
          sessionStorage.setItem(name, String(this.keyValue))
          const path = isSet ? "/againCashPassword" : "/againProof"
          this.$router.push(path)
        },
        onInput(value) {
          console.log(value)
        }
    }
}