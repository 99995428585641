<template>
  <div class="set-cash-password">
    <p class="title">设置提现身份验证密码</p>
    <div class="input-box flex flex-between">
      <input :value="valFun(1)" type="text" readonly @click="show = true" />
      <input :value="valFun(2)" type="text" readonly @click="show = true" />
      <input :value="valFun(3)" type="text" readonly @click="show = true" />
      <input :value="valFun(4)" type="text" readonly @click="show = true" />
      <input :value="valFun(5)" type="text" readonly @click="show = true" />
      <input :value="valFun(6)" type="text" readonly @click="show = true" />
    </div>
    <p class="placeholder">请输入6位数字提现身份密码</p>
    <p class="next" @click="nextFun">下一步</p>
    <p v-if="$route.path == '/setCashPassword'" class="last-text">必须设置提现身份验证码后才可继续操作提现</p>
    <van-number-keyboard
      v-model="keyValue"
      :show="show"
      :maxlength="6"
      :extra-key="'00'"
      @blur="show = false"
      @input="onInput"
    />
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>